import { DestroyRef, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { filter, switchMap, take } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { SecurityFacade } from "@cg/core/api";
import { IS_SERVER_PLATFORM } from "@cg/core/utils";
import { LoginFacade, MY_CARGLASS_LOGIN_URL } from "@cg/my-carglass-login";
import type { Resume } from "@cg/shared";
import { DetailFacade } from "../+state/detail.facade";

export function refreshGuard() {
  const cookieService = inject(CookieService);
  const detailFacade = inject(DetailFacade);
  const router = inject(Router);
  const loginFacade = inject(LoginFacade);
  const securityFacade = inject(SecurityFacade);
  const isServer = inject(IS_SERVER_PLATFORM);
  const destroyRef = inject(DestroyRef);

  if (isServer) {
    return of(true);
  }

  const customerCaseId = cookieService.get("customerCaseId");

  if (customerCaseId) {
    return securityFacade.refreshToken$.pipe(
      filter((refreshToken: string) => !!refreshToken),
      switchMap(() => {
        detailFacade.getCustomerCaseOnRefresh(customerCaseId);
        return of(true);
      }),
      takeUntilDestroyed(destroyRef)
    );
  }
  return loginFacade.resume$.pipe(
    take(1),
    switchMap((resume: Resume) => {
      if (!resume) {
        router.navigateByUrl(MY_CARGLASS_LOGIN_URL);
      }
      return of(!!resume);
    })
  );
}
