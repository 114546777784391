import { ChangeDetectionStrategy, Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { TranslocoService } from "@jsverse/transloco";
import { FooterComponent } from "@cg/footer";
import { HeaderComponent } from "@cg/header";
import { MetaDataService } from "@cg/html-header";

@Component({
  selector: "cg-frame",
  templateUrl: "./frame.component.html",
  styleUrls: ["./frame.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeaderComponent, FooterComponent, RouterOutlet]
})
export class FrameComponent {
  public constructor(
    private readonly metaDataService: MetaDataService,
    private readonly translocoService: TranslocoService
  ) {
    this.setTitle();
  }

  private setTitle(): void {
    this.translocoService.selectTranslate("myCarglass.title").subscribe((title: string) => {
      this.metaDataService.setDocumentTitle(title);
    });
  }
}
